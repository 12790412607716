var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"breadcrumb csn-breadcrumb",class:{
    'csn-breadcrumb-five': _vm.isCasinoFive,
  }},[(_vm.isPending)?_c('Loader'):_vm._e(),(!_vm.isPending)?_c('div',{staticClass:"breadcrumb-box"},[_c('div',{staticClass:"category"},[_vm._v(_vm._s(_vm.t('category')))]),_c('div',{staticClass:"page-title"},[_c('ol',{staticClass:"breadcrumb-title"},[_c('li',{staticClass:"breadcrumb-item"},[_c('span',[_vm._v(" "+_vm._s(_vm.categoryLabel)+" ")])])])])]):_vm._e(),(!_vm.isPending)?_c('div',{staticClass:"game-filters-box"},[_c('div',{staticClass:"row csn-game-short-button"},[_c('ul',{staticClass:"filters csn-breadcrumb-filters"},[_c('li',{staticClass:"csn-breadcrumb-lobby"},[_c('Route',{staticClass:"csn-breadcrumb-item",class:{
              'csn-breadcrumb-item-selected':
                _vm.currentRouteName === _vm.routeName.freePlay,
            },attrs:{"to":{ name: _vm.routeName.freePlay }}},[(_vm.isCasinoFive)?_c('FivePlayNowIcon',{staticClass:"csn-breadcrumb-icon"}):_c('span',{staticClass:"casino-icon casino-icon-lobby csn-breadcrumb-icon-platin"}),_c('div',{staticClass:"csn-breadcrumb-category-name-item"},[_vm._v(" "+_vm._s(_vm.t('play_now'))+" ")])],1)],1),_c('li',{staticClass:"csn-breadcrumb-new"},[_c('Route',{staticClass:"csn-breadcrumb-item",class:{
              'csn-breadcrumb-item-selected':
                _vm.currentRouteName === _vm.routeName.newGames,
            },attrs:{"to":{ name: _vm.routeName.newGames }}},[(_vm.isCasinoFive)?_c('FiveNewGamesIcon',{staticClass:"csn-breadcrumb-icon"}):_c('span',{staticClass:"casino-icon casino-icon-new-games csn-breadcrumb-icon-platin"}),_c('div',{staticClass:"csn-breadcrumb-category-name-item"},[_vm._v(" "+_vm._s(_vm.t('new_games'))+" ")])],1)],1),_c('li',{staticClass:"csn-breadcrumb-top"},[_c('Route',{staticClass:"csn-breadcrumb-item",class:{
              'csn-breadcrumb-item-selected':
                _vm.currentRouteName === _vm.routeName.topGames,
            },attrs:{"to":{ name: _vm.routeName.topGames }}},[(_vm.isCasinoFive)?_c('TopGamesIcon',{staticClass:"csn-breadcrumb-icon"}):_c('span',{staticClass:"casino-icon casino-icon-top-games csn-breadcrumb-icon-platin"}),_c('div',{staticClass:"csn-breadcrumb-category-name-item"},[_vm._v(" "+_vm._s(_vm.t('top_games'))+" ")])],1)],1),_vm._l((_vm.categoryList),function(ref){
            var id = ref.id;
            var name = ref.name;
            var urlSlug = ref.urlSlug;
            var image = ref.image;
return _c('li',{key:id,staticClass:"csn-breadcrumb-slot"},[_c('Route',{staticClass:"csn-breadcrumb-item",class:{
              'csn-breadcrumb-item-selected':
                _vm.currentRouteName === _vm.routeName.gameCategory &&
                _vm.currentRouteSlug === urlSlug,
            },attrs:{"to":{ name: _vm.routeName.gameCategory, params: { slug: urlSlug } }}},[_c('img',{staticClass:"csn-breadcrumb-custom-icon",class:{ 'casino-icon': !_vm.isCasinoFive },attrs:{"src":image}}),_c('div',{staticClass:"csn-breadcrumb-category-name-item"},[_vm._v(_vm._s(name))])])],1)}),_c('li',{staticClass:"csn-breadcrumb-search"},[_c('a',{staticClass:"csn-breadcrumb-item",class:{
              'csn-breadcrumb-item-selected': _vm.SHOWS_SEARCH_MENU,
            },on:{"click":_vm.openSearchMenu}},[(_vm.isCasinoFive)?_c('SearchIcon',{staticClass:"csn-breadcrumb-icon"}):_c('span',{staticClass:"casino-icon casino-icon-search csn-breadcrumb-icon-platin"}),_c('div',{staticClass:"csn-breadcrumb-category-name-item"},[_vm._v(" "+_vm._s(_vm.t('search'))+" ")])],1)])],2)])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }